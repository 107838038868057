import React from "react";

// Ionic Components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonLoading
} from "@ionic/react";

// Icons
import { chevronBack } from "ionicons/icons";

// Scripts
import getData from "../scripts/sanity.js";

// Custom Components
import Acupoints from "../components/Acupoints";
import Paywall from "../components/Paywall.jsx";

// Component
class Section extends React.Component {
  state = {
    loading: true,
    section: {
      title: "",
      description: ""
    },
    acupoints: [],
    modalOpen: false
  };
  // section
  getSection = () => {
    const section = JSON.parse(localStorage.getItem("section"));
    return section;
  };
  // acupoints
  getAcupoints = async () => {
    const query = `
			*[_type == 'track' && section._ref == "${this.state.section._id}" || ailments[]._ref in ["${this.state.section._id}"]]{
				_id,
				title,
				"audio": frequency -> audio.asset -> url,
				description,
				"image": image.asset -> url,
				video,
				"ailments": ailments[]._ref,
				premium
			} | order(title asc)
		`;
    const acupoints = await getData(query);
    return acupoints;
  };

  toggleModal = premium => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      premium: premium
    });
  };

  getSize = () => {
    let size;
    if (this.state.section.title.length < 25) {
      size = "34px";
    } else {
      size = "24px";
    }
    return {
      fontSize: size
    };
  };

  // Mount
  async componentDidMount() {
    const section = this.getSection();
    await this.setState({ section });
    const acupoints = await this.getAcupoints();
    if (acupoints.length) {
      await this.setState({ acupoints });
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <IonPage>
        <IonLoading isOpen={this.state.loading} message={"Loading..."} />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text="Back" />
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="secondary">
            <IonTitle size="large" style={this.getSize()}>
              {this.state.section.title}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: this.state.section.description
            }}
          ></div>
          {this.state.acupoints.length ? (
            <Acupoints
              acupoints={this.state.acupoints}
              toggleModal={this.toggleModal}
              premium={this.state.premium}
            />
          ) : null}
          {/* Modal */}
          <Paywall
            toggleModal={this.toggleModal}
            modalOpen={this.state.modalOpen}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default Section;
