import React from "react";

// Ionic Components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonButtons,
  IonBackButton,
  IonLoading
} from "@ionic/react";

// Icons
import { chevronBack } from "ionicons/icons";

// Scripts
import getData from "../scripts/sanity.js";

// Custom Components
import Sections from "../components/Sections";
import Groups from "../components/Groups";
import Tracks from "../components/Tracks";

// Component
class Category extends React.Component {
  state = {
    loading: true,
    category: {
      title: "",
      description: ""
    },
    sections: [],
    groups: [],
    sounds: []
  };
  // category
  getCategory = () => {
    const category = JSON.parse(localStorage.getItem("category"));
    return category;
  };
  // sections
  getSections = async () => {
    const query = `
			*[_type == 'section' && category._ref == "${this.state.category._id}"]{
				_id,
				title,
				"category": category._ref
			} | order(title asc)
		`;
    const sections = await getData(query);
    return sections;
  };
  // groups
  getGroups = async () => {
    const query = `
			*[_type == 'group' && category._ref == "${this.state.category._id}"]{
				_id,
				title,
				order,
				"frequencies": *[_type == 'frequency' && references(^._id)]{
					_id,
					title,
					description,
					"audio": audio.asset -> url
				}
			} | order(order asc)
		`;
    const groups = await getData(query);
    return groups;
  };
  // sounds
  getSounds = async () => {
    const query = `
			*[_type == 'sound' && category._ref == "${this.state.category._id}"]{
				_id,
				title,
				description,
				"audio": audio.asset -> url
			} | order(title asc)
		`;
    const sounds = await getData(query);
    return sounds;
  };

  // Set
  setSection = section => {
    localStorage.setItem("section", JSON.stringify(section));
  };

  // Mount
  async componentDidMount() {
    const category = this.getCategory();
    await this.setState({ category });
    const sections = await this.getSections();
    if (sections.length) {
      await this.setState({ sections });
    } else {
      const groups = await this.getGroups();
      if (groups.length) {
        await this.setState({ groups });
      } else {
        const sounds = await this.getSounds();
        await this.setState({ sounds });
      }
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <IonPage>
        <IonLoading isOpen={this.state.loading} message={"Loading..."} />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text="Back" />
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="secondary">
            <IonTitle size="large">{this.state.category.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: this.state.category.description
            }}
          ></div>
          {this.state.sections.length ? (
            <Sections sections={this.state.sections} />
          ) : this.state.groups.length ? (
            <Groups
              groups={this.state.groups}
              togglePlayTrack={this.props.togglePlayTrack}
              toggleLoopTrack={this.props.toggleLoopTrack}
              changeVolumeTrack={this.props.changeVolumeTrack}
              playlist={this.props.playlist}
            />
          ) : this.state.sounds.length ? (
            <Tracks
              tracks={this.state.sounds}
              togglePlayTrack={this.props.togglePlayTrack}
              toggleLoopTrack={this.props.toggleLoopTrack}
              changeVolumeTrack={this.props.changeVolumeTrack}
              playlist={this.props.playlist}
            />
          ) : null}
        </IonContent>
      </IonPage>
    );
  }
}

export default Category;
