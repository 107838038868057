import React from "react";

// Ionic Components
import { IonItem, IonLabel, IonItemGroup, IonItemDivider } from "@ionic/react";

// Scripts
import getData from "../scripts/sanity.js";

// Custom Components
import Tracks from "./Tracks";

// Component
class Groups extends React.Component {
  state = {
    groups: [
      {
        title: "",
        _id: "0",
        order: 1,
        frequencies: []
      }
    ]
  };
  componentDidMount() {}
  componentWillReceiveProps(props) {}

  render() {
    return (
      <>
        {this.props.groups.map(g => (
          <IonItemGroup key={g._id}>
            <IonItemDivider>
              <IonLabel>{g.title}</IonLabel>
            </IonItemDivider>
            <Tracks
              tracks={g.frequencies}
              togglePlayTrack={this.props.togglePlayTrack}
              toggleLoopTrack={this.props.toggleLoopTrack}
              changeVolumeTrack={this.props.changeVolumeTrack}
              playlist={this.props.playlist}
            />
          </IonItemGroup>
        ))}
      </>
    );
  }
}

export default Groups;
