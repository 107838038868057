import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonModal,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonLoading,
  IonAlert
} from "@ionic/react";

import InlineTrack from "./InlineTrack";

class PlayList extends React.Component {
  state = {};

  render() {
    return (
      <>
        <IonModal isOpen={this.props.modalOpen} swipeToClose={true}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>PlayList</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => this.props.toggleModal()}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {this.props.playlist.map(t => (
              <InlineTrack
                track={t}
                key={t._id}
                togglePlayTrack={this.props.togglePlayTrack}
                toggleLoopTrack={this.props.toggleLoopTrack}
                changeVolumeTrack={this.props.changeVolumeTrack}
                playlist={this.props.playlist}
                removeTrack={this.props.removeTrack}
                toggleModal={this.props.toggleModal}
              />
            ))}
          </IonContent>
        </IonModal>
      </>
    );
  }
}

export default PlayList;
