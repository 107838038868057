import React from "react";

// Ionic Components
import { IonItem, IonLabel } from "@ionic/react";

// Scripts
import getData from "../scripts/sanity.js";

// Custom Components
import InlineTrack from "./InlineTrack";

// Component
class Tracks extends React.Component {
  render() {
    return (
      <>
        {this.props.tracks.map(t => (
          <InlineTrack
            track={t}
            key={t._id}
            togglePlayTrack={this.props.togglePlayTrack}
            toggleLoopTrack={this.props.toggleLoopTrack}
            changeVolumeTrack={this.props.changeVolumeTrack}
            playlist={this.props.playlist}
          />
        ))}
      </>
    );
  }
}

export default Tracks;
