import React from "react";
import { useState } from "react";
import {
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonFooter,
  IonRange
} from "@ionic/react";
import { play, pause, volumeLow, volumeHigh, menu } from "ionicons/icons";
import "./GlobalPlayer.css";

import PlayList from "./PlayList";

import { getPlaylist } from "../scripts/player.js";

class GlobalPlayer extends React.Component {
  state = {
    playing: false,
    listOpen: false
  };

  checkPlaying = playlist => {
    let playing = playlist.find(
      t => t.audio.playing() || t.audio.state() == "loading"
    );
    if (playing) {
      this.setState({ playing: true });
    } else {
      this.setState({ playing: false });
    }
  };

  componentDidMount() {
    this.checkPlaying(this.props.playlist);
  }

  componentWillReceiveProps(props) {
    this.checkPlaying(props.playlist);
  }

  toggleList = () => {
    this.setState({
      listOpen: !this.state.listOpen
    });
  };

  render() {
    return (
      <IonFooter className="global-player">
        <IonToolbar>
          <IonButtons>
            {/* Play / Pause */}
            <IonButton shape="round" onClick={() => this.props.togglePlayAll()}>
              <IonIcon
                icon={this.state.playing ? pause : play}
                slot="icon-only"
              />
            </IonButton>
            {/* Volume */}
            <IonRange
              value={this.props.globalVolume * 100}
              onIonChange={e => this.props.changeVolumeAll(e.detail.value)}
            >
              <IonIcon slot="start" size="small" icon={volumeLow} />
              <IonIcon slot="end" icon={volumeHigh} />
            </IonRange>
            {/* Playlist */}
            <IonButton
              color="primary"
              slot="end"
              onClick={() => this.toggleList()}
            >
              <IonIcon icon={menu} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <PlayList
          togglePlayTrack={this.props.togglePlayTrack}
          toggleLoopTrack={this.props.toggleLoopTrack}
          changeVolumeTrack={this.props.changeVolumeTrack}
          playlist={this.props.playlist}
          removeTrack={this.props.removeTrack}
          toggleModal={this.toggleList}
          modalOpen={this.state.listOpen}
        />
      </IonFooter>
    );
  }
}

export default GlobalPlayer;
