// Packages
import React from "react";
import { Howl, Howler } from "howler";

// Ionic Components
import {
  IonButtons,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonItemGroup
} from "@ionic/react";
import { heartOutline, heart, closeCircleOutline } from "ionicons/icons";
import InlinePlayer from "./InlinePlayer";

// Scripts
import {
  getTrack,
  toggleFavoriteTrack,
  getFavorite
} from "../scripts/player.js";

import "./InlineTrack.css";

class InlineTrack extends React.Component {
  state = {
    track: {}
  };

  setAcupoint = () => {
    if (this.state.track.image) {
      localStorage.setItem("acupoint", JSON.stringify(this.state.track._id));
      if (this.props.toggleModal) {
        this.props.toggleModal();
      }
    }
  };

  toggleFavorite = async e => {
    e.preventDefault();
    e.stopPropagation();

    let track = this.state.track;
    track.favorite = !track.favorite;
    this.setState({ track });

    toggleFavoriteTrack(this.state.track._id);
  };

  removeTrack = async e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.removeTrack(this.props.track._id);
  };

  getAttributes = (trackData, playlist) => {
    let track = playlist.find(t => t._id === trackData._id) || trackData;
    track.favorite = getFavorite(track._id);
    return track;
  };

  componentDidMount() {
    let track = this.props.track;
    track = this.getAttributes(track, this.props.playlist);
    this.setState({ track });
  }

  async componentWillReceiveProps(props) {
    let track = props.track;
    track = this.getAttributes(track, props.playlist);
    this.setState({ track });
  }

  render() {
    return (
      <IonItemGroup>
        <IonItem
          className="track-item"
          routerLink={
            this.state.track.image
              ? `/acupoints/${this.state.track._id}`
              : undefined
          }
          onClick={e => this.setAcupoint()}
        >
          <IonLabel>
            <IonIcon
              icon={this.state.track.favorite ? heart : heartOutline}
              color="primary"
              onClick={e => this.toggleFavorite(e)}
              className="heart-button"
              size="regular"
            />
            {this.props.removeTrack ? (
              <IonIcon
                icon={closeCircleOutline}
                color="danger"
                onClick={e => this.removeTrack(e)}
                className="delete-button"
                size="regular"
              />
            ) : null}
            <h4>{this.state.track.title}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.track.description }}
              className="description-content"
            ></div>
          </IonLabel>
        </IonItem>
        <IonItem className="player-item">
          <InlinePlayer
            togglePlayTrack={this.props.togglePlayTrack}
            toggleLoopTrack={this.props.toggleLoopTrack}
            changeVolumeTrack={this.props.changeVolumeTrack}
            track={this.state.track}
          />
        </IonItem>
      </IonItemGroup>
    );
  }
}

export default InlineTrack;
