import React from "react";
import ReactPlayer from "react-player";

// Ionic Components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading
} from "@ionic/react";

// Icons
import { chevronBack } from "ionicons/icons";

// Scripts
import getData from "../scripts/sanity.js";

// CSS
import "./Info.css";

// Component
class Info extends React.Component {
  state = {
    info: {
      description: "",
      video: null
    },
    loading: true
  };

  getInfo = async () => {
    const query = `
			*[_type == 'info']{
				_id,
				description,
				video,
				"imageUrl": image.asset->url
			}[0]
		`;
    const info = await getData(query);
    console.log({ info });
    return info;
  };

  async componentDidMount() {
    const info = await this.getInfo();
    this.setState({
      info,
      loading: false
    });
  }
  render() {
    return (
      <IonPage>
        <IonLoading isOpen={this.state.loading} message={"Loading..."} />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text="Back" />
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonTitle size="large">Info</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.info.description
            }}
            className="html-content"
          ></div>
          {this.state.info.imageUrl ? (
            <img src={this.state.info.imageUrl} class="info-img" />
          ) : null}
          {this.state.info.video ? (
            <ReactPlayer
              url={`https://vimeo.com/${this.state.info.video}`}
              width="100%"
              responsive="true"
              controls={true}
              playsinline={true}
              color="ff9933"
            />
          ) : null}
        </IonContent>
      </IonPage>
    );
  }
}

export default Info;
