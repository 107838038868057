import React from "react";
import { IonButtons, IonIcon, IonRange, IonSpinner } from "@ionic/react";
import {
  pause,
  play,
  volumeLow,
  volumeHigh,
  reloadCircle
} from "ionicons/icons";
import "./InlinePlayer.css";

class InlinePlayer extends React.Component {
  isNotString(track) {
    if (track.audio && typeof track.audio !== "string") {
      return true;
    } else {
      return false;
    }
  }
  isLoading(track) {
    if (this.isNotString(track) && track.audio.state() == "loading") {
      return true;
    } else {
      return false;
    }
  }
  isPlaying(track) {
    if (this.isNotString(track) && track.audio.playing()) {
      return true;
    } else {
      return false;
    }
  }
  isLoaded(track) {
    if (this.isNotString(track) && track.audio.state() == "loaded") {
      return true;
    } else {
      return false;
    }
  }
  volumeOrDefault(track) {
    if (this.isNotString(track)) {
      if (track.audio.volume() === 0) {
        return 0;
      } else {
        if (this.isLoading(track)) {
          return 50;
        }
        return track.audio.volume() * 100 || 50;
      }
    } else {
      return 50;
    }
  }
  getLoopColor(track) {
    if (this.isNotString(track)) {
      return track.audio.loop() ? "primary" : "medium";
    } else {
      return "medium";
    }
  }
  render() {
    return (
      <IonButtons className="player-controls">
        {/* Play / Pause */}
        {this.isLoading(this.props.track) ? (
          <IonSpinner color="primary" />
        ) : (
          <IonIcon
            icon={this.isPlaying(this.props.track) ? pause : play}
            slot="icon-only"
            onClick={() => this.props.togglePlayTrack(this.props.track)}
            color="primary"
          />
        )}
        {/* Loop */}
        <IonIcon
          disabled={!this.isLoaded(this.props.track)}
          icon={reloadCircle}
          slot="icon-only"
          onClick={() => {
            if (this.isLoaded(this.props.track)) {
              this.props.toggleLoopTrack(this.props.track._id);
            }
          }}
          color={this.getLoopColor(this.props.track)}
        />
        {/* Volume */}
        <IonRange
          disabled={!this.isLoaded(this.props.track)}
          value={this.volumeOrDefault(this.props.track)}
          onIonChange={e =>
            this.props.changeVolumeTrack(this.props.track._id, e.detail.value)
          }
        >
          <IonIcon slot="start" size="small" icon={volumeLow} />
          <IonIcon slot="end" icon={volumeHigh} />
        </IonRange>
      </IonButtons>
    );
  }
}

export default InlinePlayer;
