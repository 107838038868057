import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactPlayer from "react-player";

// Ionic Components
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner
} from "@ionic/react";

// Icons
import {
  chevronBack,
  chevronDownCircle,
  chevronUpCircle,
  playCircle,
  pauseCircle,
  reloadCircle,
  videocam,
  videocamOff,
  heart,
  heartOutline
} from "ionicons/icons";

// CSS
import "./Track.css";

// Scripts
import getData from "../scripts/sanity.js";
import {
  getTrack,
  toggleFavoriteTrack,
  getFavorite
} from "../scripts/player.js";

class Acupoint extends React.Component {
  state = {
    acupoint: {
      _id: "0",
      title: "",
      audio: "",
      description: "",
      image: "",
      video: null
    },
    isOpen: true,
    videoOpen: false,
    videoPlaying: false
  };

  isNotString(track) {
    if (track.audio && typeof track.audio !== "string") {
      return true;
    } else {
      return false;
    }
  }
  isLoading(track) {
    if (this.isNotString(track) && track.audio.state() == "loading") {
      return true;
    } else {
      return false;
    }
  }
  isPlaying(track) {
    if (this.isNotString(track) && track.audio.playing()) {
      return true;
    } else {
      return false;
    }
  }
  isLoaded(track) {
    if (this.isNotString(track) && track.audio.state() == "loaded") {
      return true;
    } else {
      return false;
    }
  }
  volumeOrDefault(track) {
    if (this.isNotString(track)) {
      if (track.audio.volume() === 0) {
        return 0;
      } else {
        if (this.isLoading(track)) {
          return 50;
        }
        return track.audio.volume() * 100 || 50;
      }
    } else {
      return 50;
    }
  }
  getLoopColor(track) {
    if (this.isNotString(track)) {
      return track.audio.loop() ? "primary" : "medium";
    } else {
      return "medium";
    }
  }

  toggleFavorite = async e => {
    e.preventDefault();
    e.stopPropagation();

    let acupoint = this.state.acupoint;
    acupoint.favorite = !acupoint.favorite;
    this.setState({ acupoint });

    toggleFavoriteTrack(this.state.acupoint._id);
  };

  // PANEL

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  // VIDEO

  toggleVideo = () => {
    this.setState({
      videoOpen: !this.state.videoOpen
    });
  };

  toggleVideoPlaying = () => {
    this.setState({
      videoPlaying: true
    });
  };

  // TRACK

  getTrack = async playlist => {
    let acupointId = JSON.parse(localStorage.getItem("acupoint"));
    let acupoint = playlist.find(t => t._id == acupointId);
    if (!acupoint) {
      let acupointId = localStorage.getItem("acupoint");
      const query = `
				*[ _type == 'track' && _id == ${acupointId} ]
				{
					_id,
					title,
					description,
					"image": image.asset -> url,
					"audio": sound -> audio.asset -> url,
					video
				}[0]
			`;
      acupoint = await getData(query);
    }
    acupoint.favorite = getFavorite(acupoint._id);
    return acupoint;
  };

  // LIFECYCLE

  async componentDidMount() {
    let acupoint = await this.getTrack(this.props.playlist);
    this.setState({ acupoint });
  }

  async componentWillReceiveProps(props) {
    let acupoint = await this.getTrack(props.playlist);
    this.setState({ acupoint });
  }

  // RENDER

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text="Back" />
            </IonButtons>
            <IonTitle>{this.state.acupoint.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          {/* Image */}
          <TransformWrapper>
            <TransformComponent>
              <img src={this.state.acupoint.image} alt="" />
            </TransformComponent>
          </TransformWrapper>
          <IonCard
            slot="fixed"
            className={`description-panel ${
              this.state.isOpen ? "open" : "closed"
            }`}
          >
            <IonCardContent>
              <div className="title">
                <h2>{this.state.acupoint.title}</h2>
                <IonIcon
                  icon={this.state.isOpen ? chevronDownCircle : chevronUpCircle}
                  className="toggle-arrow"
                  onClick={() => this.toggleOpen()}
                  color="secondary"
                />
              </div>
              <div className="player">
                {/* Play / Pause */}
                {this.isLoading(this.state.acupoint) ? (
                  <IonSpinner color="primary" className="play-spinner" />
                ) : (
                  <IonIcon
                    icon={
                      this.isPlaying(this.state.acupoint)
                        ? pauseCircle
                        : playCircle
                    }
                    slot="icon-only"
                    onClick={() =>
                      this.props.togglePlayTrack(this.state.acupoint)
                    }
                    color="primary"
                    className="icon-button"
                  />
                )}
                {/* Loop */}
                {this.isLoaded(this.state.acupoint) ? (
                  <IonIcon
                    icon={reloadCircle}
                    slot="icon-only"
                    className="icon-button"
                    onClick={() => {
                      if (this.isLoaded(this.state.acupoint)) {
                        this.props.toggleLoopTrack(this.state.acupoint._id);
                      }
                    }}
                    color={this.getLoopColor(this.state.acupoint)}
                  />
                ) : null}
                {/* Favorite */}
                <IonIcon
                  icon={this.state.acupoint.favorite ? heart : heartOutline}
                  slot="icon-only"
                  color="primary"
                  className="icon-heart"
                  onClick={e => this.toggleFavorite(e)}
                />
                {/* Video */}
                {this.state.acupoint.video ? (
                  <IonIcon
                    icon={this.state.videoOpen ? videocamOff : videocam}
                    slot="icon-only"
                    color="primary"
                    className="icon-video"
                    onClick={() => this.toggleVideo()}
                  />
                ) : null}
              </div>
              <div>{this.state.acupoint.title}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.acupoint.description
                }}
                className="html-scroll"
              ></div>
            </IonCardContent>
          </IonCard>
          {this.state.videoOpen ? (
            <div className="video-player">
              <ReactPlayer
                url={`https://vimeo.com/${this.state.acupoint.video}`}
                width="100%"
                height="100%"
                controls="true"
                playsinline="true"
                playing="true"
                color="ff9933"
                onReady={e => this.toggleVideoPlaying()}
              />
              {this.state.videoPlaying ? null : (
                <IonSpinner name="crescent" size="lg" />
              )}
            </div>
          ) : null}
        </IonContent>
      </IonPage>
    );
  }
}

export default Acupoint;
