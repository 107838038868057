import sanityClient from "@sanity/client";
import blocksToHtml from "@sanity/block-content-to-html";

const client = sanityClient({
  projectId: "trur2u5c",
  dataset: "production",
  apiVersion: "v1", // use current UTC date - see "specifying API version"!
  useCdn: false // `false` if you want to ensure fresh data
});

const toHTML = description => {
  return blocksToHtml({
    blocks: description
  });
};

const getData = async query => {
  const data = await client.fetch(query, {});
  // Array
  if (Array.isArray(data) && data.length) {
    const cleanData = data.map(d => {
      if (d.description) {
        d.description = toHTML(d.description);
      }
      for (let key in d) {
        if (Array.isArray(d[key]) && d[key].length) {
          d[key] = d[key].map(n => {
            if (n.description) {
              n.description = toHTML(n.description);
            }
            return n;
          });
        }
      }
      return d;
    });
    return cleanData;
  }
  // Object
  else if (typeof data === "object" && data !== null) {
    const cleanData = data;
    if (cleanData.description) {
      cleanData.description = toHTML(cleanData.description);
    }
    return cleanData;
  }
  // Other
  return data;
};

export default getData;
