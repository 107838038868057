import React from "react";

// Ionic Components
import { IonItem, IonLabel } from "@ionic/react";

// Component
class Sections extends React.Component {
  state = {
    sections: [
      {
        title: "",
        _id: "0"
      }
    ]
  };

  setSection = section => {
    localStorage.setItem("section", JSON.stringify(section));
  };

  render() {
    return (
      <>
        {this.props.sections.map(s => (
          <IonItem
            key={s._id}
            routerLink={`/section/${s._id}`}
            onClick={e => this.setSection(s)}
          >
            <IonLabel color="primary">{s.title}</IonLabel>
          </IonItem>
        ))}
      </>
    );
  }
}

export default Sections;
