// Packages
import React from "react";
import { Howl, Howler } from "howler";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { App as Cap } from "@capacitor/app";

// Custom Components
import Home from "./pages/Home.jsx";
import Category from "./pages/Category.jsx";
import Section from "./pages/Section.jsx";
import Tracks from "./pages/Tracks.jsx";
import Acupoint from "./pages/Acupoint.jsx";
import Info from "./pages/Info.jsx";
import Start from "./pages/Start.jsx";
import Favorites from "./pages/Favorites.jsx";
import GlobalPlayer from "./components/GlobalPlayer.jsx";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// Listen for App switch foreground / background
Cap.addListener("appStateChange", ({ isActive }) => {
  if (!isActive) {
  }
});

// APP

class App extends React.Component {
  state = {
    playlist: [],
    globalVolume: 0.5
  };

  // PLAYLIST

  removeTrack = trackId => {
    let playlist = this.state.playlist;
    let track = playlist.find(t => t._id == trackId);
    if (track) {
      track.audio.stop();
      track.audio.volume(0.5);
    }
    playlist = playlist.filter(t => t._id !== trackId);
    this.setState({ playlist });
  };

  updatePlaylist = () => {
    let playlist = this.state.playlist;
    this.setState({ playlist });
  };

  // PLAY / PAUSE

  togglePlayTrack = trackData => {
    let playlist = this.state.playlist;
    let track = playlist.find(t => t._id == trackData._id);
    // Track is in playlist
    if (track) {
      if (track.audio.playing() || track.audio.state() == "loading") {
        track.audio.pause();
      } else {
        track.audio.play();
      }
      // togglePlayTrack(track._id);
    }
    // Track is not in playlist
    else {
      track = trackData;
      console.log(`track ${track._id} is loading`);
      if (typeof track.audio == "string") {
        track.audio = new Howl({
          src: track.audio,
          preload: true,
          onload: () => {
            console.log(`track ${track._id} loaded`);
            this.updatePlaylist();
          },
          onend: () => {
            this.stopOrLoop(track._id);
          }
        });
      }
      track.audio.volume(0.5);
      track.audio.play();
      playlist.push(track);
      // togglePlayTrack(track._id);
    }
    this.setState({ playlist });
  };

  togglePlayAll = () => {
    let playlist = this.state.playlist;
    let playing = playlist.find(
      t => t.audio.playing() || t.audio.state() == "loading"
    );
    // Play all
    if (!playing) {
      playlist = playlist.map(t => {
        t.audio.play();
        return t;
      });
      // playAll();
    }
    // Pause All
    else {
      playlist = playlist.map(t => {
        t.audio.pause();
        return t;
      });
      // pauseAll();
    }
    this.setState({ playlist });
  };

  // LOOP

  stopOrLoop = trackId => {
    let playlist = this.state.playlist;
    let track = playlist.find(t => t._id === trackId);
    if (!track.audio.loop()) {
      track.audio.stop();
      this.setState({ playlist });
    }
  };

  toggleLoopTrack = trackId => {
    let playlist = this.state.playlist;
    let track = playlist.find(t => t._id === trackId);
    if (track) {
      track.audio.loop(!track.audio.loop());
      this.setState({ playlist });
    }
  };

  // VOLUME

  changeVolumeTrack = async (trackId, vol) => {
    if (!isNaN(vol)) {
      let playlist = this.state.playlist;
      let track = playlist.find(t => t._id === trackId);
      if (track) {
        let volume = vol ? vol / 100 : 0;
        track.audio.volume(volume);
        this.setState({ playlist });
        // changeVolumeTrack(track._id, volume);
      }
    }
  };

  changeVolumeAll = vol => {
    Howler.volume(vol / 100);
    this.setState({ globalVolume: vol / 100 });
  };

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" exact={true}>
              <Home />
            </Route>
            <Route path="/info">
              <Info />
            </Route>
            <Route path="/start">
              <Start />
            </Route>
            <Route path="/favorites">
              <Favorites
                togglePlayTrack={this.togglePlayTrack}
                toggleLoopTrack={this.toggleLoopTrack}
                changeVolumeTrack={this.changeVolumeTrack}
                playlist={this.state.playlist}
              />
            </Route>
            <Route path="/categories/:id">
              <Category
                togglePlayTrack={this.togglePlayTrack}
                toggleLoopTrack={this.toggleLoopTrack}
                changeVolumeTrack={this.changeVolumeTrack}
                playlist={this.state.playlist}
              />
            </Route>
            <Route path="/section/:id">
              <Section />
            </Route>
            <Route path="/acupoints/:id">
              <Acupoint
                togglePlayTrack={this.togglePlayTrack}
                toggleLoopTrack={this.toggleLoopTrack}
                changeVolumeTrack={this.changeVolumeTrack}
                playlist={this.state.playlist}
              />
            </Route>
          </IonRouterOutlet>
          <GlobalPlayer
            togglePlayTrack={this.togglePlayTrack}
            toggleLoopTrack={this.toggleLoopTrack}
            changeVolumeTrack={this.changeVolumeTrack}
            playlist={this.state.playlist}
            togglePlayAll={this.togglePlayAll}
            changeVolumeAll={this.changeVolumeAll}
            globalVolume={this.state.globalVolume}
            removeTrack={this.removeTrack}
          />
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default App;
