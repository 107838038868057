import React from "react";

// Ionic Components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonGrid,
  IonButton,
  IonButtons,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonLoading
} from "@ionic/react";

// Icons
import { informationCircle, heart } from "ionicons/icons";

// Scripts
import getData from "../scripts/sanity.js";

// CSS
import "./Home.css";

// Component
class Home extends React.Component {
  state = {
    loading: true,
    logo: "",
    categories: [
      {
        _id: "0",
        title: "",
        imageUrl: "",
        order: 0
      }
    ],
    startItem: {}
  };

  getLogo = async () => {
    const query = `
			*[_type == 'logo']{
				_id,
				"imageUrl": image.asset->url
			}[0]
		`;
    const logo = await getData(query);
    return logo;
  };

  getCategories = async () => {
    const query = `
			*[_type == 'category']{
				_id,
				title,
				description,
				"imageUrl": image.asset->url,
				order
			} | order(order asc)
		`;
    const categories = await getData(query);
    return categories;
  };

  getStartItem = async () => {
    const query = `
			*[_type == 'start']{
				_id,
				title,
				"imageUrl": image.asset->url
			}[0]
		`;
    const startItem = await getData(query);
    return startItem;
  };

  getPaywall = async () => {
    const query = `
			*[_type == 'paywall'] {
				description
			}[0]
		`;
    const paywall = await getData(query);
    localStorage.setItem("paywall", JSON.stringify(paywall.description));
  };

  getSections = async () => {
    const query = `
			*[_type == 'section']{
				_id,
				title,
				"category": category._ref
			} | order(title asc)
		`;
    const sections = await getData(query);
    this.setState({ sections });
  };

  setCategory = category => {
    localStorage.setItem("category", JSON.stringify(category));
  };
  async componentDidMount() {
    const logo = await this.getLogo();
    const categories = await this.getCategories();
    const startItem = await this.getStartItem();
    this.setState({ logo, categories, startItem, loading: false });
    this.getPaywall();
  }

  // Start
  // Categories
  // let categories_res = await axios.get(
  //   "https://trur2u5c.api.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20'category'%5D%7B%0A%20%20_id%2C%0A%20%20title%2C%0A%20%20description%2C%0A%20%20%22imageUrl%22%3A%20image.asset-%3Eurl%2C%0A%20%20order%0A%7D%20%7C%20order(order%20asc)"
  // );
  // let categories = categories_res.data.result;
  // categories = categories.map(c => {
  //   if (c.description) {
  //     c.description = blocksToHtml({
  //       blocks: c.description
  //     });
  //   }
  //   return c;
  // });
  // Sections
  // let sections_res = await axios.get(
  //   "https://trur2u5c.api.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20'section'%5D%7B%0A%20%20_id%2C%0A%20%20title%2C%0A%20%20%22category%22%3A%20category._ref%0A%7D%20%7C%20order(title%20asc)"
  // );
  // let sections = sections_res.data.result;
  // Tracks
  // let tracks_res = await axios.get(
  //   "https://trur2u5c.api.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20'track'%5D%7B%0A%09_id%2C%0A%20%20title%2C%0A%20%20description%2C%0A%20%20premium%2C%0A%20%20%22image%22%3A%20image.asset-%3Eurl%2C%0A%20%20%22section%22%3A%20section._ref%2C%0A%20%20%22audio%22%3A%20audio.asset-%3Eurl%2C%0A%20%20video%2C%0A%20%20%22category%22%3A%20category._ref%0A%7D%20%7C%20order(title%20asc)"
  // );
  // let tracks = tracks_res.data.result;
  // tracks = tracks.map(t => {
  //   if (t.description) {
  //     t.description = blocksToHtml({
  //       blocks: t.description
  //     });
  //   }
  //   return t;
  // });
  // Paywall
  // let paywall_res = await axios.get(
  //   "https://trur2u5c.api.sanity.io/v1/data/query/production?query=*%5B_type%20%3D%3D%20'paywall'%5D%20%7B%0A%20%20description%0A%7D%5B0%5D"
  // );
  // let paywall = paywall_res.data.result;
  // if (paywall.description) {
  //   paywall.description = blocksToHtml({
  //     blocks: paywall.description
  //   });
  //   localStorage.setItem("paywall", JSON.stringify(paywall.description));
  // }
  // Merge
  //     categories = categories.map(c => {
  //       c.sections = sections.filter(s => s.category == c._id);
  //       c.sections = c.sections.map(s => {
  //         s.tracks = tracks.filter(t => t.section == s._id);
  //         return s;
  //       });
  //       if (!c.sections.length) {
  //         c.tracks = tracks.filter(t => t.category == c._id);
  //       }
  //       return c;
  //     });
  //     // State
  //     setCategories(categories_res.data.result);
  //     setLoading(false);
  //   };
  //   getCategories();
  // }, []);

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton routerLink="/favorites" routerDirection="forward">
                <IonIcon icon={heart} color="primary" />
              </IonButton>
            </IonButtons>
            <IonTitle>Natural Synergy</IonTitle>
            <IonButtons slot="end">
              <IonButton routerLink="/info" routerDirection="forward">
                <IonIcon icon={informationCircle} color="primary" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonImg src={this.state.logo.imageUrl} className="logo" />
          <IonGrid className="grid">
            <IonRow>
              {this.state.loading ? (
                <IonLoading
                  isOpen={this.state.loading}
                  message={"Loading..."}
                />
              ) : (
                <>
                  <IonCol size="6">
                    <IonCard className="menu-item" routerLink="/start">
                      <IonImg src={this.state.startItem.imageUrl} />
                      <IonCardContent>
                        {this.state.startItem.title}
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  {this.state.categories.map(c => (
                    <IonCol size="6" key={c._id}>
                      <IonCard
                        className="menu-item"
                        routerLink={`/categories/${c._id}`}
                        onClick={e => this.setCategory(c)}
                      >
                        <IonImg src={c.imageUrl} />
                        <IonCardContent>{c.title}</IonCardContent>
                      </IonCard>
                    </IonCol>
                  ))}
                </>
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
}

export default Home;
