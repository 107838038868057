import React from "react";
import axios from "axios";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonModal,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonLoading,
  IonAlert
} from "@ionic/react";
class Paywall extends React.Component {
  state = {
    description: "",
    error: false,
    success: false,
    loading: false
  };
  login = async e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    let form = new FormData();
    form.append("log", e.target[0].value);
    form.append("pwd", e.target[1].value);
    let res = await axios({
      method: "post",
      url: "https://naturalsynergysolution.net/wp-login.php",
      data: form,
      headers: { "Content-Type": "multipart/form-data" }
    });
    this.setState({
      loading: false
    });
    if (res.request.responseURL.match(/member/gm)) {
      this.dismiss();
    } else {
      this.setState({
        error: true
      });
    }
  };
  // Loading / Submit
  dismiss = () => {
    this.setState({
      error: false,
      success: true
    });
    localStorage.setItem("premium", "true");
    setTimeout(() => {
      this.setState({
        success: false
      });
      this.props.toggleModal(true);
    }, 1000);
  };
  componentDidMount() {
    const description = JSON.parse(localStorage.getItem("paywall"));
    this.setState({ description });
  }
  render() {
    return (
      <>
        <IonModal isOpen={this.props.modalOpen} swipeToClose={true}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Unlock Premium</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => this.props.toggleModal()}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <form className="form" onSubmit={e => this.login(e)}>
              <div
                dangerouslySetInnerHTML={{ __html: this.state.description }}
              ></div>
              <IonItem>
                <IonLabel position="floating">Username</IonLabel>
                <IonInput type="text" />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" />
              </IonItem>
              <IonButton type="submit" expand="block">
                Login
              </IonButton>
              {this.state.error ? (
                <IonText color="danger" className="error">
                  Incorrect Username or Password
                </IonText>
              ) : (
                ""
              )}
            </form>
          </IonContent>
        </IonModal>
        <IonLoading
          isOpen={this.state.loading}
          message={"Please wait..."}
        ></IonLoading>
        <IonAlert
          isOpen={this.state.success}
          message={"Successfully logged in"}
        />
      </>
    );
  }
}

export default Paywall;
