import React from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import { withIonLifeCycle } from "@ionic/react";

// Ionic Components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonLoading,
  IonIcon,
  IonItem,
  IonLabel,
  IonRange,
  NavContext
} from "@ionic/react";

// Icons
import {
  chevronBack,
  pauseCircle,
  playCircle,
  pause,
  play,
  heart,
  volumeLow,
  volumeHigh
} from "ionicons/icons";

// Scripts
import getData from "../scripts/sanity.js";
import {
  getPlayingTracks,
  addPlayingTrack,
  removePlayingTrack
} from "../scripts/player.js";

// Custom Components
import InlineTrack from "../components/InlineTrack";

// CSS
import "./Favorites.css";

// Component
class Favorites extends React.Component {
  state = {
    tracks: [],
    loading: true
  };

  async ionViewDidEnter() {
    let favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    favorites = JSON.stringify(favorites.map(t => t._id));
    const query = `
			*[ (_type == 'track' || _type == 'sound' || _type == 'frequency') && _id in ${favorites}]
			{
				_id,
				title,
				description,
				"image": image.asset -> url,
				_type == 'track' => { "audio": sound -> audio.asset -> url },
				_type != 'track' => { "audio": audio.asset -> url },
				video
			}
		`;
    const tracks = await getData(query);
    this.setState({ tracks, loading: false });
  }

  toggleFavorite = trackId => {
    let tracks = this.state.tracks.filter(t => t._id !== trackId);
    this.setState({ tracks });
  };

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text="Home" />
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonTitle size="large">Favorites</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonLoading isOpen={this.state.loading} message={"Loading..."} />
          <div>
            {this.state.tracks.map(t => (
              <InlineTrack
                track={t}
                key={t._id}
                togglePlayTrack={this.props.togglePlayTrack}
                toggleLoopTrack={this.props.toggleLoopTrack}
                changeVolumeTrack={this.props.changeVolumeTrack}
                toggleFavorite={this.toggleFavorite}
                playlist={this.props.playlist}
              />
            ))}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Favorites);
