import React from "react";

// Ionic Components
import { IonItem, IonLabel, IonChip } from "@ionic/react";

// Component
class Acupoints extends React.Component {
  state = {
    acupoints: []
  };

  // Select
  setAcupoint = acupoint => {
    acupoint.premium && !this.state.premium
      ? this.props.toggleModal()
      : localStorage.setItem("acupoint", JSON.stringify(acupoint._id));
  };

  checkPremium = () => {
    let premium = localStorage.getItem("premium");
    if (premium) {
      this.setState({ premium: true });
    }
  };

  componentDidMount() {
    this.checkPremium();
  }

  componentWillReceiveProps(props) {
    if (props.premium) {
      this.checkPremium();
    }
  }

  render() {
    return (
      <>
        {this.props.acupoints.map(a => (
          <IonItem
            key={a._id}
            routerLink={
              a.premium && !this.state.premium
                ? undefined
                : `/acupoints/${a._id}`
            }
            onClick={e => this.setAcupoint(a)}
          >
            <IonLabel color="primary">{a.title}</IonLabel>
            {a.premium && !this.state.premium ? (
              <IonChip color="primary">Premium</IonChip>
            ) : null}
          </IonItem>
        ))}
      </>
    );
  }
}

export default Acupoints;
