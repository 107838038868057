// export const getPlaylist = () => {
//   return JSON.parse(localStorage.getItem("playlist")) || [];
// };
//
// const setPlaylist = data => {
//   localStorage.setItem("playlist", JSON.stringify(data));
// };
//
// export const getTrack = trackId => {
//   const playlist = getPlaylist();
//   return playlist.find(t => t._id === trackId);
// };
//
// export const playAll = () => {
//   let playlist = getPlaylist();
//   playlist = playlist.map(t => {
//     t.playing = true;
//     return t;
//   });
//   setPlaylist(playlist);
// };
//
// export const pauseAll = () => {
//   let playlist = getPlaylist();
//   playlist = playlist.map(t => {
//     t.playing = false;
//     return t;
//   });
//   setPlaylist(playlist);
// };
//
// export const togglePlayTrack = trackId => {
//   let playlist = getPlaylist();
//   let track = playlist.find(t => t._id == trackId);
//   if (track) {
//     track.playing = !track.playing;
//   } else {
//     playlist.push({
//       _id: trackId,
//       volume: "0.5",
//       playing: true
//     });
//   }
//   setPlaylist(playlist);
// };
//
// export const removeTrack = trackId => {
//   let playlist = getPlaylist();
//   playlist = playlist.filter(t => t._id !== trackId);
//   setPlaylist(playlist);
// };
//
// export const changeVolumeTrack = (trackId, vol) => {
//   let playlist = getPlaylist();
//   let track = playlist.find(t => t._id == trackId);
//   if (track) {
//     track.volume = vol;
//   }
//   setPlaylist(playlist);
// };

export const getFavorite = trackId => {
  const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
  return favorites.find(t => t._id == trackId) ? true : false;
};

export const toggleFavoriteTrack = trackId => {
  let favorites = JSON.parse(localStorage.getItem("favorites")) || [];
  let track = favorites.find(t => t._id == trackId);
  if (track) {
    favorites = favorites.filter(t => t._id !== trackId);
  } else {
    favorites.push({
      _id: trackId
    });
  }
  localStorage.setItem("favorites", JSON.stringify(favorites));
};
