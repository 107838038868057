import React from "react";
import ReactPlayer from "react-player";

// Ionic Components
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonLoading
} from "@ionic/react";

// Icons
import { chevronBack } from "ionicons/icons";

// Scripts
import getData from "../scripts/sanity.js";

// CSS
import "./Info.css";

// Component
class Start extends React.Component {
  state = {
    start: {
      title: "",
      description: "",
      video: null
    },
    loading: true
  };

  getStart = async () => {
    const query = `
			*[_type == 'start']{
				_id,
				title,
				description,
				video
			}[0]
		`;
    const start = await getData(query);
    return start;
  };

  async componentDidMount() {
    const start = await this.getStart();
    this.setState({
      start,
      loading: false
    });
  }
  render() {
    return (
      <IonPage>
        <IonLoading isOpen={this.state.loading} message={"Loading..."} />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text="Back" />
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonTitle size="large">{this.state.start.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.start.description
            }}
            className="html-content"
          ></div>
          {this.state.start.video ? (
            <div>
              <ReactPlayer
                url={`https://vimeo.com/${this.state.start.video}`}
                responsive="true"
                width="100%"
                controls={true}
                playsinline={true}
                color="ff9933"
              />
            </div>
          ) : null}
        </IonContent>
      </IonPage>
    );
  }
}

export default Start;
